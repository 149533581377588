import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/structure/layout.js'
import Seo from '../components/essentials/seo.js'

const Cookies = () => {
  const {t} = useTranslation();

  return (
    <Layout>
      <Seo title={t('title') | Cookies} />
    
      <div className="">
        Cookies text
      </div>

    </Layout>
  );
};

export default Cookies;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index", "landing"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;